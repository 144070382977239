import React from 'react';
import './Home.css';
import logo from '../logo.png'; // Ensure you have a logo file in the src folder
import ServerCard from '../components/ServerCard';

import steam from '../Assets/steam.png';
import discord from '../Assets/discord.png';
import twitter from '../Assets/twitter.png';
import youtube from '../Assets/youtube.png';

const Home = () => {
    return (
        <div>
            <header className="hero">
                <div className="hero-overlay">
                    <img src={logo} className="hero-logo" alt="logo" />
                    <h1>Rust4Noobs</h1>
                    <p>The best place to start your journey.</p>
                    <div className='social-links'>
                        <div className='social-link'>
                            <a href='https://discord.gg/7Zb3rjz' target='_blank' rel='noreferrer noopener'>
                                <img src={discord} alt='discord' />
                            </a>
                        </div>
                        <div className='social-link'>
                            <a href='https://steamcommunity.com/groups/Rust4Noobs' target='_blank' rel='noreferrer noopener'>
                                <img src={steam} alt='steam' />
                            </a>
                        </div>
                        <div className='social-link'>
                            <a href='https://twitter.com/Rust4Noobs' target='_blank' rel='noreferrer noopener'>
                                <img src={twitter} alt='twitter' />
                            </a>
                        </div>
                        <div className='social-link'>
                            <a href='https://www.youtube.com/@Rust4Noobs' target='_blank' rel='noreferrer noopener'>
                                <img src={youtube} alt='discord' />
                            </a>
                        </div>
                    </div>

                </div>

            </header>
            <div className="server-card-container">
                <ServerCard serverid={25448728} />
                <ServerCard serverid={26889920} />
                <ServerCard serverid={25441004} />
                <ServerCard serverid={25442368} />
                <ServerCard serverid={26994476} />
                <ServerCard serverid={25449499} />
                <ServerCard serverid={27976533}/>
                <ServerCard serverid={27976530} />
                <ServerCard serverid={27976571} />
                <ServerCard serverid={27976570} />
                <ServerCard serverid={27976572} />
            </div>
        </div>



    );
}

export default Home;
