import React, { useState, useEffect } from 'react';
import './ServerCard.css';

const ServerCard = ({ serverid }) => {
    const [serverData, setServerData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [mapImageUrl, setMapImageUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        fetch(`https://r4n.cyphersoftware.dev/api/serverstats?serverID=${serverid}`)
            .then(response => {
                if (!response.ok) {
                    console.error('Network response was not ok');
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Convert ISO 8601 date to a more readable format
                const date_wipe = new Date(data.data.data.attributes.details.rust_last_wipe).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });
                // Add the new date_wipe key to the data object
                data.data.data.attributes.details.date_wipe = date_wipe;
                setServerData(data.data.data.attributes);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [serverid]);

    const unixTimestampToDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp );
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });

    };


    const fetchMapImage = async (seed, size) => {
        const apiUrl = `https://api.rustmaps.com/v4/maps/${size}/${seed}?staging=false`;

        try {
            const response = await fetch(apiUrl, {
                headers: {
                    'X-API-Key': 'bee61614-f5c4-4a8b-9106-3e16e0e8ad1f',
                },
            });
            const data = await response.json();
            if (data.meta.status === 'Success' && data.data && data.data.imageIconUrl) {
                return data.data.imageIconUrl;
            } else {
                throw new Error('Failed to fetch map image URL');
            }
        } catch (error) {
            console.error('Error fetching map image:', error);
            return null;
        }
    };

    const handleMapClick = async () => {
        const { rust_world_seed, rust_world_size } = serverData.details;

        const imageUrl = await fetchMapImage(rust_world_seed, rust_world_size);
        if (imageUrl) {
            setMapImageUrl(imageUrl);
            setIsModalOpen(true);
        } else {
            alert('Unable to retrieve map image.');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setMapImageUrl(null);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="server-card">
            <div className='header-item'>
                {serverData.details.rust_headerimage && (
                    <img src={serverData.details.rust_headerimage} alt="server" />
                )}
            </div>
            <div className='server-count'>
                <p>{serverData.players} / {serverData.maxPlayers} Players Online</p>
            </div>
            <div className='server-info'>
                <h2>{serverData.name}</h2>
                {console.log(serverData)}
                <p>Last Wipe: {serverData.details.date_wipe}</p>
                <p className='updated-text'>Last Updated: {unixTimestampToDate(serverData.last_updated)}</p>
            </div>

            <div className='button-container'>
                <button className='server-button' onClick={() => alert('Connect button clicked')}>Connect</button>
                <button className='view-button' onClick={handleMapClick}>View Map</button>
            </div>

            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='server-name'>{serverData.name}</div>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img src={mapImageUrl} alt="Rust Map" className="map-image" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServerCard;
