import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Rules from './pages/Rules';
import Staff from './pages/Staff';
import Store from './pages/Store';
import './App.css';
import FourOFour from './pages/FourOFour';
import LeaderBoardMain from './pages//leaderboards/LeaderBoardMain';
import LeaderBoardTrio from './pages/leaderboards/LeaderBoardTrio';
import LeaderBoardDuo from './pages/leaderboards/LeaderBoardDuo';
import LeaderBoardSolo from './pages/leaderboards/LeaderBoardSolo';
import LeaderBoardPVE from './pages/leaderboards/LeaderBoardPVE';

import FAQ from './pages/FAQ';
import { AuthContext, AuthProvider } from './AuthContext';
import { useEffect } from 'react';

function AppContent() {
  const { login } = React.useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      login(token);
      navigate('/'); // Remove the token from the URL
    }
  }, [login, navigate]);

  return (
    <div className="App">

      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/leaderboards" element={<h1>Leaderboard</h1>} />
        <Route path="/leaderboard/main" element={<LeaderBoardMain />} />
        <Route path="/leaderboard/trio" element={<LeaderBoardTrio />} />
        <Route path="/leaderboard/duo" element={<LeaderBoardDuo />} />
        <Route path='/leaderboard/solo' element={<LeaderBoardSolo/>} />
        <Route path='/leaderboard/pve' element={<LeaderBoardPVE/>} />
        <Route path='/FAQ' element={<FAQ/>} />
        <Route path='/staff' element={<Staff/>} />
        <Route path="*" element={<FourOFour />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
