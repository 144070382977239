import React from 'react';
import './About.css';
import logo from '../logo.png';

const About = () => {
    return (
        <div className='page-container'>
        <section className="section">

            <h2>About Rust 4 Noobs</h2>
            <h3>Rust4Noobs was established with the mission of providing a welcoming and supportive environment for both seasoned players and newcomers to the game of Rust. Our aim is to create a community where individuals can learn, play, and grow together. Our dedicated and friendly team is always on hand, equipped with the knowledge and readiness to assist players at any stage of their journey. We take pride in our carefully designed servers, which offer a balanced introduction to Rust—challenging enough to be engaging but forgiving enough for beginners. At Rust4Noobs, we strive to be the ultimate starting point for your adventure in Rust, offering the best blend of learning and fun.</h3>
            <img src={logo} className='about-logo' alt="Rust4Noobs Logo" />
        </section>
        </div>
    );
}

export default About;
