import './Staff.css'

import featherfall from '../Assets/featherfall.jpg'
import illum from '../Assets/illuminatixs.jpg'
import igotcookie from '../Assets/iGotAcookie.jpg'
import Shors from '../Assets/Shors.jpg'
import catma from '../Assets/catma.jpg'

const Staff = () => {
    return (
        <div>
            <div className="staff-brief">
                <h1>Staff</h1>
                <p>Our staff team is here to help you with any issues you may have. Please be respectful and follow their instructions.</p>
            </div>

            <div className="staff-list">
                <div className="staff-member">
                    <img src={featherfall} alt="staff-member" />
                    <h1>FeatherFall</h1>
                    <h3>The Founder and Director of 4Noobs.co</h3>
                    <p>Maintaining daily operation of the organisation and pretending to do important things.</p>
                </div>
                <div className="staff-member">
                    <img src={illum} alt="staff-member" />
                    <h1>Illuminatixs</h1>
                    <h3>The Staff Manager for 4Noobs.co</h3>
                    <p>Making sure everything runs smoothly day by day, and keeping Feather in check.</p>
                </div>
                <div className="staff-member">
                    <img src={igotcookie} alt="staff-member" />
                    <h1>iGotAcookie</h1>
                    <h3>Media Manager for 4Noobs.co</h3>
                    <p>The man behind our social media presence across instagram, youtube, and tiktok.</p>
                </div>
                <div className="staff-member">
                    <img src={Shors} alt="staff-member" />
                    <h1>Shors</h1>
                    <h3>Admin at Rust4Noobs</h3>
                    <p>Shors — Today at 02:49 im not good at these kind of things</p>
                </div>
                <div className="staff-member">
                    <img src={catma} alt="staff-member" />
                    <h1>Catma</h1>
                    <h3>Admin at Rust4Noobs</h3>
                    <p>Looks like a cat, has a name with cat in it. But probably is not a cat.</p>
                </div>
                </div>
        </div>
    );
}

export default Staff;