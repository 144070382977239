import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="social-links">
                <a href="https://www.youtube.com/@Rust4Noobs">Youtube</a>
                <a href="https://www.instagram.com/rust4noobs_official/">Instagram</a>
            </div>
            <div className='legal-links'>
                <a href="https://cdn.4noobs.co/docs/PRIVACY_POLICY_4NOOBS.CO.pdf" target='_blank'>Privacy Policy</a>
                <a href="https://4noobs.co/faq" target='_blank'>Terms of Service</a>
            </div>
            <br/>
            <div>© 2024 R4Noobs.co - All rights reserved.</div>
            
        </footer>
    );
}

export default Footer;
